*:not(.spinner-grow) {
  border-radius: 0 !important; }

nav .navbar-toggler {
  display: block !important; }

[v-cloak] {
  display: none; }

.app-site-menu-fix {
  position: fixed;
  top: 0;
  width: 250px;
  z-index: 1200;
  height: 100%;
  left: -250px;
  min-height: 100%;
  max-height: 100%;
  background: #fff;
  transition-property: left;
  transition-duration: 500ms;
  border-right: 1px #E0E0E0 solid;
  overflow-y: auto; }

#app.app-site-menu-fix-open .app-site-menu-fix {
  left: 0; }

#navigation {
  padding: 0;
  margin: 0; }

#navigation > li {
  padding: 1rem;
  margin: 0; }

#app.nav-fixed .container {
  padding-top: 80px; }

.pagination {
  max-width: 100%;
  overflow-x: auto; }

.smiles-edit-mode .smiles-list {
  display: block; }

.smiles-and-cats .cats-col {
  max-height: 400px;
  overflow-y: auto; }

@media screen and (max-width: 720px) {
  .smiles-and-cats {
    flex-direction: column; }
  .smiles-and-cats .cats-col {
    margin: 0;
    max-height: none;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto; }
  .smiles-and-cats .cats-col .list-group {
    flex-direction: row;
    margin-bottom: 1rem; }
  .smiles-and-cats .cats-col .list-group .list-group-item {
    border-radius: 0 !important;
    white-space: nowrap;
    border: none; } }

.smile-edit {
  position: absolute;
  width: 300px;
  background: #fff; }
